/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

type NameMetaObj = {
  name: string
  content: string
}

type PropertyMetaObj = {
  property: string
  content: string
}

type Meta = ConcatArray<NameMetaObj | PropertyMetaObj>

type SeoProps = {
  description?: string
  lang?: string
  meta?: Meta,
  title?: string
}

function Seo({ description, lang, meta = [], title }: SeoProps) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description
  const defaultTitle = site.siteMetadata?.title

  const allMeta = [
    {
      name: `description`,
      content: metaDescription,
    },
    {
      property: `og:title`,
      content: title,
    },
    {
      property: `og:image`,
      content: require("../../images/meta/ogimage.png"),
    },
    {
      property: `og:description`,
      content: metaDescription,
    },
    {
      property: `og:type`,
      content: `website`,
    },
    {
      name: `twitter:card`,
      content: `summary`,
    },
    {
      name: `twitter:creator`,
      content: site.siteMetadata?.author || ``,
    },
    {
      name: `twitter:title`,
      content: title,
    },
    {
      name: `twitter:description`,
      content: metaDescription,
    }
  ].concat(meta)

  return (
    <Helmet
      htmlAttributes={{ lang }}
      defaultTitle={defaultTitle}
      titleTemplate={defaultTitle ? `%s | ${defaultTitle}` : null}
    >
      {
        title && (
          <title>{title}</title>
        )
      }
      {allMeta.map(function ({ name, content, property }, i) {
        return <meta key={`meta-${i}`} name={name} content={content} property={property} />
      })}
    </Helmet>
  )
}

export default Seo
