import * as React from "react"
import Container from '../Container/Container'

type SectionProps = {
  id?: string
  children: JSX.Element
  fullWidth?: boolean
  className?: string
}

function Section({
  id,
  children,
  fullWidth,
  className,
}: SectionProps) {
  const classNames = ["py-4 md:py-10 lg:py-20"]

  if (className) {
    classNames.push(className)
  }

  return (
    <Container id={id} fullWidth={fullWidth} className={classNames.join(" ")}>
      {children}
    </Container>
  )
}

export default Section;
